<template>
  <div class="mobile_div layout pb-4" :class="{ main_view: homeConditions }">
    <AppsComponent
      v-if="homeConditions && !store.onboardingPending"
      class="responsiveDisplay"
    />
    <div
      class="w-full mx-auto pb-4 overflow-auto"
      :class="{ main_page: homeConditions, homePage: !homeConditions }"
    >
      <div class="relative">
        <TopNavbar
          class="pr-12"
          v-if="homeConditions && !store.onboardingPending"
          @show-dropdown="(data) => (showAppsDropdown = data)"
          @show-profileDropdown="(data) => (showProfiledropdown = data)"
        />
        <div class="flex z-[200]">
          <ProfileDropdown
            v-if="showProfiledropdown"
            @show-profileDropdown="(data) =>showProfiledropdown = data"
          />
          <AppsNavigation
            v-if="showAppsDropdown"
            @show-dropdown="(data) => (showAppsDropdown = data)"
            @mouse-leave-apps="removeDropdowns"
       
          />
        </div>
      </div>

      <div
        class="
          px-[16px]
          py-[32px]
          mb-20
          rounded-lg
          mx-6
          BG0
          mt-[105px]
          relative
          responsiveDisplay
        "
      >

        <!-- <router-link to="/"></router-link> -->
        <router-view v-slot="{ Component }" class="BG0">
          <keep-alive>
            <component :is="Component" :key="Component" />
          </keep-alive>
        </router-view>
      </div>
    </div>
    <!-- <ForMobile
      class="w-[100vw] flex justify-center items-center absolute tabletView"
      :maintext="'This is page is unavailble on Tablet'"
      :sub-text="'For optimum result please continue on pc' "
    /> -->
  
    
  </div>
</template>
<script setup lang="ts">
import {  computed, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "./store";
import AppsComponent from "./components/AppsComponent.vue";
import TopNavbar from "./components/TopNavbar.vue";
import ProfileDropdown from "./components/ProfileDropdown.vue";
import AppsNavigation from "./components/AppsNavigation.vue";
import ForMobile from "./apps/time-tracker/components/UIComponents/ForMobile.vue";

const store = useStore();
const router = useRouter();

const showProfiledropdown = ref(false);
const showAppsDropdown = ref(false);


const removeDropdowns = () =>{
    showAppsDropdown.value = false;
  showProfiledropdown.value = false;
};


const homeConditions = computed(() => {
  return (
    router.currentRoute.value.name != "login" &&
    router.currentRoute.value.name != "forgotPassword" &&
    router.currentRoute.value.name != "CreatePassword" &&
    router.currentRoute.value.name != "ChangePassword" &&
    router.currentRoute.value.name != "ResetPassword" &&
    router.currentRoute.value.name != "SendingEmail"
  );
});







</script>
<style lang="scss">
body {
  overflow-y: hidden;
}
#app {

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
* {
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  width: 8px;
  background-color: #d8dae5;
  margin: 0;
  height: 8px;
}
*::-webkit-scrollbar-thumb {
  background-color: #d8dae5;
  border-radius: 8px;
}
*::-webkit-scrollbar-track {
  background-color: #fff;
}

@supports (-moz-appearance:none) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #d8dae5 #fff;
    border-radius: 8px;
  }
}
.main_view {
  background-color: #edeff5;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-attachment: fixed;
  background-size: contain;
}
.main_page {
  box-sizing: border-box;
  margin-left: 100px;
  height: 100vh;
}
.homePage {
  height: 100dvh;
  box-sizing: border-box;
}
.layout {
  width: 100%;
}
nav {
  padding: 30px;
}
a {
  color: #dd5928;
  &.router-link-exact-active {
    color: #dd5928;
  }
}
.btn-shadow {
  box-shadow: 0px 4px 10px -5px rgba(221, 89, 40, 0.3);
}
.btn-shadow:disabled {
  box-shadow: none;
}
.btn-shadow:hover {
  box-shadow: none;
}

.SPC-MT-100 {
  margin-top: 8px;
}
.SPC-MT-200 {
  margin-top: 16px;
}
.SPC-MT-300 {
  margin-top: 24px;
}
.SPC-MT-400 {
  margin-top: 32px;
}
.SPC-MT-500 {
  margin-top: 40px;
}
.SPC-MT-600 {
  margin-top: 48px;
}
.SPC-MB-100 {
  margin-bottom: 8px;
}
.SPC-MB-200 {
  margin-bottom: 16px;
}
.SPC-MB-300 {
  margin-bottom: 24px;
}
.SPC-MB-400 {
  margin-bottom: 32px;
}
.SPC-MB-500 {
  margin-bottom: 40px;
}
.SPC-MB-600 {
  margin-bottom: 48px;
}
.SPC-ML-100 {
  margin-left: 8px;
}
.SPC-ML-200 {
  margin-left: 16px;
}
.SPC-ML-300 {
  margin-left: 24px;
}
.SPC-ML-400 {
  margin-left: 32px;
}
.SPC-ML-500 {
  margin-left: 40px;
}
.SPC-ML-600 {
  margin-left: 48px;
}
.SPC-MR-100 {
  margin-right: 8px;
}
.SPC-MR-200 {
  margin-right: 16px;
}
.SPC-MR-300 {
  margin-right: 24px;
}
.SPC-MR-400 {
  margin-right: 32px;
}
.SPC-MR-500 {
  margin-right: 40px;
}
.SPC-MR-600 {
  margin-right: 48px;
}
/* FONTSTYLES */
.H900 {
  font-weight: 700;
  font-size: 2.074rem;
  line-height: 40px;
}
.H800 {
  font-weight: 700;
  font-size: 1.728rem;
  line-height: 32px;
}
.H700 {
  font-weight: 700;
  font-size: 1.44rem;
  line-height: 28px;
}
.H600 {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 24px;
}
.H500 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
}
.H400 {
  font-weight: 700;
  font-size: 0.833rem;
  line-height: 16px;
}
.H300 {
  font-weight: 700;
  font-size: 0.694rem;
  line-height: 12px;
}
.H200 {
  font-weight: 500;
  font-size: 0.694rem;
  line-height: 12px;
}
.H100 {
  font-weight: 700;
  font-size: 0.579rem;
  line-height: 12px;
}
.P300 {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 24px;
}
.P250 {
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
}
.P200 {
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
}
.P100 {
  font-weight: 400;
  font-size: 0.833rem;
  line-height: 16px;
}
.P50 {
  font-weight: 400;
  font-size: 0.5813rem;
  line-height: 12px;
}
/* COLOURS */
/* Neutrals */
.N900 {
  color: #101840;
}
.N800 {
  color: #474d66;
}
.N700 {
  color: #696f8c;
}
.N600 {
  color: #8f95b2;
}
.N500 {
  color: #c1c4d6;
}
.N400 {
  color: #d8dae5;
}
.N300 {
  color: #e6e8f0;
}
.N200 {
  color: #edeff5;
}
.N100 {
  color: #f4f6fa;
}
.N75 {
  color: #f9fafc;
}
.N50 {
  color: #fafbff;
}
.N0 {
  color: #ffffff;
}
.O600 {
  color: #893516 !important;
}
.O500 {
  color: #b5461d !important;
}
.O400 {
  color: #dd5928 !important;
}
.O300 {
  color: #e47a53 !important;
}
.O200 {
  color: #eb9c7f !important;
}
.O100 {
  color: #f2beab !important;
}
.O50 {
  color: #f9e0d7 !important;
}
.R600 {
  color: #7d2828 !important;
}
.R500 {
  color: #a73636 !important;
}
.R400 {
  color: #d14343 !important;
}
.R300 {
  color: #ee9191 !important;
}
.R200 {
  color: #f4b6b6 !important;
}
.R100 {
  color: #f9dada !important;
}
.R50 {
  color: #fdf4f4 !important;
}
.G600 {
  color: #317159 !important;
}
.G500 {
  color: #429777 !important;
}
.G400 {
  color: #52bd94 !important;
}
.G300 {
  color: #a3e6cd !important;
}
.G200 {
  color: #dcf2ea !important;
}
.G100 {
  color: #eef8f4 !important;
}
.G50 {
  color: #f5fbf8 !important;
}
.mobile_div {
  display: flex;
}
.BG0 {
  background-color: #ffffff;
}
a.disabled {
  pointer-events: none;
  cursor: default;
}
label {
  margin-left: 0 !important;
}
@media (max-width: 670px) {

  .main_page {
    box-sizing: border-box;
    margin-left: 0px;
    margin-top: 70px;
  }
}

.dp__input {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 3rem !important;
  min-width: 10rem !important;
  text-align: center;
}

.month-picker .dp__input {
  max-width: 12.2rem !important;
}


.dp__theme_light {
  --dp-primary-color: #f9e0d7 !important;
  --dp-primary-text-color: #dd5928 !important;
  --dp-text-color: #474d66 !important;
  --dp-secondary-color: #d8dae5 !important;
  --dp-icon-color: #8f95b2;
}

.dp__calendar_header_item {
  color: #8f95b2 !important;
  font-weight: 400 !important;
}

.dp__btn {
  color: #8f95b2 !important;
}

.dp__calendar_header_separator {
  display: none !important;
}

:root {
  --dp-border-radius: 0.5rem !important;
}

@media screen and (min-width: 746px) {
  .tabletView {
    display: none !important;
  }

  
}
@media screen and (max-width: 745px) {
  .tabletView {
    display: block;
    margin: auto;
    height: 100vh;
  }

  .responsiveDisplay {
    display: none !important;
  }
}

</style>