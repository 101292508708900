<template>
  <div
    class="
      N700
      flex
      justify-end
      content-center
      bg-[#EDEFF5]
      fixed
      top-0
      pt-8
      pb-4
      pr-20
      left-10
      w-full
    "
  >
    <div class="flex justify-between w-full">
      <div class="flex items-center cursor-pointer z-[500]">
      </div>
      <div class="flex gap-4">
        <div
          class="
            self-center
            cursor-pointer
            flex
            items-center
            gap-2
            bg-[#C1C4D6]
            p-2
            rounded-lg
            responsiveDisplay
          "
          @click="showAppsDropdown = !showAppsDropdown"
      
        >
          <MenuSvg/>
          <p class="N900 text-base">Apps</p>
        </div>
        <div
          @click="redirectToHelp"
          class="
            cursor-pointer
            bg-[#C1C4D6]
            rounded-lg
            flex
            self-center
            gap-2
            p-2
          "
        >
          <HelpSvg/>
        </div>
        <div
          class="flex gap-4 cursor-pointer"
          @click="
            [
              (showProfiledropdown = !showProfiledropdown),
            ]
          "
          
        >
          <ProfileImageComponent
          :imgSrc="profilePicture"
          :initials="getUserNameInitials(full_name)"
          widthSize="2.5rem"
          heightSize="2.5rem"
          text-size="text-2l"
          />
          <p class="self-center N800 username">{{ userName }}</p>
          <img
            src="@/assets/arrow-down.svg"
            class="cursor-pointer h-fit self-center"
            alt="arrow-icon"
          />
        </div>
      </div>
    </div>
  </div>

           <!--handle breadcrumbs here-->
  <div class="mx-10 py-10 -mb-40 mt-20">
    
    <!--banner handled here-->
    <div  v-if="trackRoute === '/'" class="-mb-10">
      <BreadCrumbsComponent
        v-if="trackRoute === '/'"
        :breadcrumbs="breadcrumbDashboard"
      />
    </div>
    <BreadCrumbsComponent
      v-if="trackRoute === 'ProcurementApprovalsPage'"
      :breadcrumbs="breadcrumbsprocurementapprovals"
    />
    <BreadCrumbsComponent
      v-if="trackRoute === 'ManagersDashboardPage'"
      :breadcrumbs="breadcrumbsprocurementmanagerdashboard"
    />
    <BreadCrumbsComponent
      v-if="trackRoute === 'RequisitionFormPage'"
      :breadcrumbs="breadcrumbsprocurementrequisition"
    />
    <div v-if="trackRoute === 'SuppliersPage'" class="-mb-10">
      <BreadCrumbsComponent
       v-if="trackRoute === 'SuppliersPage'"
      :breadcrumbs="breadcrumbsprocurementsuppliers"
    />
    </div>
    <div v-if="trackRoute === 'SettingsPage'" class="-mb-10">
      <BreadCrumbsComponent
      v-if="trackRoute === 'SettingsPage'"
      :breadcrumbs="breadcrumbsprocurementsettings"
    />
    </div>
    <BreadCrumbsComponent
      v-if="trackRoute === 'RequestItemPage'"
      :breadcrumbs="breadcrumbsrequestitempage"
    />
    <BreadCrumbsComponent
      v-if="trackRoute === 'CreateSuppliersPage'"
      :breadcrumbs="breadcrumbscreatesupplierspage"
    />
    <BreadCrumbsComponent
      v-if="trackRoute === 'CreateAccessLevelPage'"
      :breadcrumbs="breadcrumbscreateaccesslevel"
    />
    <BreadCrumbsComponent
      v-if="trackRoute === 'RequisitionDetailsPage' && determineRoute.requisitionRoute === 'personal'"
      :breadcrumbs="breadcrumbrequestdetailpersonal"
    />

    <BreadCrumbsComponent
      v-if="trackRoute === 'RequisitionDetailsPage' && determineRoute.requisitionRoute === 'staff'"
      :breadcrumbs="breadcrumbrequestdetailstaff"
    />

    </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, watchEffect } from "vue";
import { useStore } from "@/store/index";
import { getUserNameInitials } from "@/helpers/functions";
import ProfileImageComponent from "@/ui-kit/components/Avatar/ProfileImageComponent.vue";
import { useRouter, useRoute } from "vue-router";
import MenuSvg from "@/apps/procurement-service/assets/MenuSvg.vue";
import HelpSvg from "@/apps/procurement-service/assets/HelpSvg.vue";
import BreadCrumbsComponent from "@/ui-kit/bread-crumbs/BreadCrumbsComponent.vue";
import { checkRequisitionRoute } from "@/apps/procurement-service/procurement-store/procStore";


const store = useStore();
const userName = ref(store.user.first_name);

const full_name = computed(
  () => store?.user?.first_name + " " + store?.user.last_name
);
const profilePicture = ref(store.user?.profile_image);

store.$subscribe((_, state) => {
  profilePicture.value = state.user.profile_image;
  userName.value = state.user.first_name;
});

const showProfiledropdown = ref<boolean>(false);
const showAppsDropdown = ref<boolean>(false);



const router = useRouter();
const route = useRoute();
const determineRoute = checkRequisitionRoute();


//habdle breadcrumb here
const trackRoute = computed(() => {
  return route.name;
});
const breadcrumbDashboard = ref([
  { label: "Dashboard", to: "/" },
]);
const breadcrumbsprocurementapprovals = ref([
  { label: "Dashboard", to: "/" },
  { label: "Procurement Approvals", to: "" },
]);

const breadcrumbsprocurementrequisition = ref([
  { label: "Dashboard", to: "/" },
  { label: "Your Requisitions", to: "" }
]);


const breadcrumbsprocurementmanagerdashboard = ref([
  { label: "Dashboard", to: "/" },
  { label: "Staff Requisitions", to: "" },
]);

const breadcrumbsprocurementsuppliers = ref([
  { label: "Dashboard", to: "/" },
  { label: "Suppliers", to: "" }
]);

const breadcrumbscreatesupplierspage = ref([
  { label: "Dashboard", to: "/" },
  {label: "Suppliers", to: "/procurement-suppliers" },
  { label: "Create Suppliers", to: "" },
]);

const breadcrumbsprocurementsettings = ref([
  { label: "Dashboard", to: "/" },
  { label: "Settings", to: "" }
]);

const breadcrumbsrequestitempage= ref([
  { label: "Dashboard", to: "/" },
  {label: "Request Item", to:""}
]);



const breadcrumbscreateaccesslevel = ref([
  { label: "Dashboard", to: "/" },
  { label: "Settings Page", to: "/settings" },
  { label: "Create Access Level Page", to: "" },
]);

const breadcrumbrequestdetailpersonal = ref([
  {label:"Dashboard", to:"/"},
  {label:"Your Requisition", to:"/procurement-requisition"},
  {label:"Request Detail", to:""}
])

const breadcrumbrequestdetailstaff = ref([
  {label:"Dashboard", to:"/"},
  {label:"Staff Requisition", to:"/procurement-managers-dashboard"},
  {label:"Request Detail", to:""}
])


const redirectToHelp = () => {
  router.push("/help");
};
const emits = defineEmits(['show-profileDropdown', 'show-dropdown']);


watch([showProfiledropdown, showAppsDropdown], ([profile, apps])=>{
    emits('show-profileDropdown', profile);
    emits('show-dropdown', apps)
});

</script>

<style>
@media (max-width: 420px) {
  .profile-img {
    border-radius: 50%;
  }
  .username {
    display: none;
  }
}
</style>