import PageNotFound from '../../PageNotFound.vue';
export default [
  {
    path: '/procurement-approvals',
    name: 'ProcurementApprovalsPage',
    meta: { auth: true },
    component: () =>
      import(
        /*webpackChunkName: "procurement-approvals" */ '../views/ProcurementApprovalsPage.vue'
      )
  },
  {
    path: '/procurement-requisition',
    name: 'RequisitionFormPage',
    meta: { auth: true },
    component: () =>
      import(
        /*webpackChunkName: "procurement-requisition" */ '../views/RequisitionFormPage.vue'
      )
  },
  {
    path: '/procurement-details/:id',
    name: 'RequisitionDetailsPage',
    meta: { auth: true },
    component: () =>
      import(
        /*webpackChunkName: "procurement-details" */ '../views/RequisitionDetailsPage.vue'
      )
  },
  {
    path: '/procurement-managers-dashboard',
    name: 'ManagersDashboardPage',
    meta: { auth: true },
    component: () =>
      import(
        /*webpackChunkName: "procurement-managers-dashboard" */ '../views/ManagersDashboardPage.vue'
      )
  },
  {
    path: '/procurement-suppliers',
    name: 'SuppliersPage',
    meta: { auth: true },
    component: () =>
      import(
        /*webpackChunkName: "procurement-suppliers" */ '../views/SuppliersPage.vue'
      )
  },
  {
    path: '/request-item/:id',
    name: 'RequestItemPage',
    meta: { auth: true },
    component: () =>
      import(
        /*webpackChunkName: "requisiting-item" */ '../views/RequestItemPage.vue'
      )
  },
  {
    path: '/requisitioning-steps-and-timelines',
    name: 'RequisitionPdfPage',
    meta: { auth: true },
    component: () =>
      import(
        /*webpackChunkName: "requisition-policies" */ '../views/RequisitionPdfPage.vue'
      )
  },
  {
    path: '/procurement-policies',
    name: 'ProcurementPoliciesPdfPage',
    meta: { auth: true },
    component: () =>
      import(
        /*webpackChunkName: "procurement-policies" */ '../views/ProcurementPoliciesPdfPage.vue'
      )
  },
  {
    path: '/create-supplier',
    name: 'CreateSuppliersPage',
    meta: { auth: true },
    component: () =>
      import(
        /*webpackChunkName: "create-supplier" */ '../views/CreateSuppliersPage.vue'
      )
  },
  {
    path: '/edit-supplier/:id',
    name: 'EditSuppliersPage',
    meta: { auth: true },
    component: () =>
      import(
        /*webpackChunkName: "edit-supplier" */ '../views/EditSupplierPage.vue'
      )
  },
  {
    path: '/procurement-manager-purchase-orders/:id',
    name: 'PurchaseOrder',
    meta: { auth: true },
    component: () =>
      import(
        /*webpackChunkName: "procurement-manager-purchase-orders" */ '../views/HopPurchaseOrdersPage.vue'
      )
  },
  {
    path: '/payment-requests/:id',
    name: 'PaymentRequests',
    meta: { auth: true },
    component: () =>
      import(
        /*webpackChunkName: "payment-requests" */ '../views/PaymentRequestsPage.vue'
      )
  },
  {
    path: '/settings',
    name: 'SettingsPage',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "config" */ '../views/SettingsPage.vue')
  },
  {
    path: '/create-access-level',
    name: 'CreateAccessLevelPage',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "addAccessLevel" */ '../views/CreateAccessLevelPage.vue')
  },
  {
    path: '/help',
    name: 'help page',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "addAccessLevel" */ '../../../components/HelpPage.vue')
  },
];
