import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  useRoute,
  useRouter,
} from "vue-router";
import PageNotFound from "../apps/authentication/components/PageNotFound.vue";
import { getToken, getUserid, isLoggedIn, logout } from "../services/auth";
import { encodeUrlPath } from "@/helpers/utils";
import procurementRoutes from "../apps/procurement-service/routes/procRoutes";

const env = process.env;
const microsoftLoginUrl = "https://amalitech-sso.amalitech-dev.net/login";
const route: Array<RouteRecordRaw> = [
{
    path: "/login",
    name: "login",
    alias: "/login",
    component: () =>
      import((window.location.href = `${env.VUE_APP_MAIN_PAGE_URL}login`)),
    meta: { auth: false }
  },
  {
    path: "/",
    alias: "/procurement-dashboard",
    name: "ProcurementDashboardPage",
    component: () =>
      import(
        /* webpackChunkName: "signin" */ "../apps/procurement-service/views/ProcurementDashboardPage.vue"
      ),
    meta: { auth: true },
  },
  // {
  //   path: "/forgot-password",
  //   name: "forgotPassword",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "password-reset" */ "../apps/authentication/components/ForgotPassword.vue"
  //     ),
  // },
  // {
  //   path: "/sending-email",
  //   name: "SendingEmail",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../apps/authentication/components/SendingEmail.vue"
  //     ),
  // },
  // {
  //   path: "/change-password",
  //   name: "ChangePassword",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../apps/authentication/components/ChangePassword.vue"
  //     ),
  // },
  // {
  //   path: "/create-password",
  //   name: "CreatePassword",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../apps/authentication/views/createPassword.vue"
  //     ),
  // },
  {
    path: "/myglobalcomponentsarehere",
    name: "ShowGlobal",
    component: () =>
      import(/* webpackChunkName: "global" */ "@/ui-kit/GlobalComponents.vue"),
  },

  {
    path: "/legacy",
    name: "legacy",
    component: () =>
      (window.location.href = `${
        process.env.VUE_APP_ERP_API
      }/auth?token=${getToken()}`),
    //  import(/* webpackChunkName: "legacy" */ "@/apps/HomePage.vue"),
    meta: { auth: true },
    beforeEnter: (_to, _from, next) => {
      if (isLoggedIn()) {
        window.location.href = `${
          process.env.VUE_APP_ERP_API
        }/auth?token=${getToken()}`;
      } else {
        next();
      }
    },
  },

  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ "../apps/authentication/components/ForgotPassword.vue"
      ),
  },

  {
    path: "/sending-email",
    name: "SendingEmail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../apps/authentication/components/SendingEmail.vue"
      ),
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../apps/authentication/components/ChangePassword.vue"
      ),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../apps/authentication/components/ResetPassword.vue"
      ),
  },

  {
    path: "/user-profile",
    name: "user-profile",
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal`),
  },
  {
    path: "/request-loan",
    name: "request-loan",
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal/loans`),
  },

  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { auth: true },
  },

  /** End of Auth routes */
];

const isProcurementRoutesEnabled = !env.VUE_APP_PROCUREMENT_ROUTES_ENABLED;

export const routes = route.concat(
  isProcurementRoutesEnabled ? procurementRoutes : []
);

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _from, next) => {
  if (to.meta.auth && to.query["app-token"]) {
      next();
  }
  else if (to.meta.auth && !isLoggedIn()){
    const redirect: string = env.VUE_APP_ENVIRONMENT==="local"?  window.location.protocol +"//"+ window.location.host : window.location.host;
    window.location.href =  `${env.VUE_APP_MAIN_PAGE_URL}sso-login?redirect-to=${redirect}`;
  }
  else {
    next();
  }
});


export default router;
