import { defineStore } from 'pinia';
import {
  Requisition,
  CapApproverInt,
  BusinessDetails,
  BankDetails,
  Attachments,
  Supplier,
  ProcurementPurchaseOrder
} from '../interfaces/types';
import {
  GET_ALL_CAPAPPROVER,
  GET_ALL_REQUISITION,
  GET_ALL_CAPAPPROVERS,
  FILTER_CAPAPPROVERS,
  GET_ALL_SUPPLIERS,
  SEARCH_SUPPLIER
} from '../graphQl/queries';
import { useQuery } from '@vue/apollo-composable';
import creatPersistedState from 'pinia-plugin-persistedstate';

//use to toggle capApproverForm
export const toggleCapApproverForm = defineStore({
  id: 'toggleCapApproverForm',
  state: () => ({
    toggleDialogue: false
  }),
  actions: {
    setToggleCapAppForm(data: boolean) {
      this.toggleDialogue = data;
    }
  }
});

//use to handle procurement mangers file uploads
export const procurementUploadStatus = defineStore({
  id: 'procurementUploadStatus',
  state: () => ({
    successOrFailed: '',
    successOrFailedDocs: '',
    deleteState: '',
    uploadlength: 0,
    requestforinvoice: false,
    messages: '',
    proformainvoiveid: 0,
    procDocActive: false,
    approvalButActive: false,
    radioselect: 0,
    enableorderDialog: false,
    supplierIdForPurchaseOrder: 0,
    purchaseOrderStats: '',
    viewpurchaseorder: '',
    deletePurchaseOrderStat: '',
    updatePurchaseOrder: '',
    summaryDocName:"",

    //used to set purchaseorder information
    purchaseOrderData_ref_no: '',
    purchaseOrderData_item_requested: '',
    purchaseOrderData_requisition_id: 0,
    purchaseOrderData_supplier_id: 0,
    purchaseOrderData_purchase_id: 0
  }),
  actions: {
    setSuccessOrFailed(data: string) {
      this.successOrFailed = data;
    },
    setsuccessOrFailedDocs(data: string) {
      this.successOrFailedDocs = data;
    },
    setUploadlength(data: number) {
      this.uploadlength = data;
    },
    setDeleteState(data: string) {
      this.deleteState = data;
    },
    setrequestforinvoice(data: boolean) {
      this.requestforinvoice = data;
    },
    setMessages(data: string) {
      this.messages = data;
    },
    setproformainvoiveid(data: number) {
      this.proformainvoiveid = data;
    },
    setprocDocActive(data: boolean) {
      this.procDocActive = data;
    },
    setapprovalButActive(data: boolean) {
      this.approvalButActive = data;
    },
    setradioselect(data: number) {
      this.radioselect = data;
    },
    setEnableorderDialog(data: boolean) {
      this.enableorderDialog = data;
    },
    setsupplierIdForPurchaseOrder(data: number) {
      this.supplierIdForPurchaseOrder = data;
    },
    setpurchaseOrderStats(data: string) {
      this.purchaseOrderStats = data;
    },
    setviewpurchaseorder(data: string) {
      this.viewpurchaseorder = data;
    },
    setdeletePurchaseOrderStat(data: string) {
      this.deletePurchaseOrderStat = data;
    },
    setProformatSummaryDocsName(data:string){
      this.summaryDocName = data;
    },

    //used to set purchaseorder information
    setpurchaseOrderData_ref_no(data: string) {
      this.purchaseOrderData_ref_no = data;
    },
    setpurchaseOrderData_requisition_id(data: number) {
      this.purchaseOrderData_requisition_id = data;
    },
    setpurchaseOrderData_supplier_id(data: number) {
      this.purchaseOrderData_supplier_id = data;
    },
    setpurchaseOrderData_item_requested(data: string) {
      this.purchaseOrderData_item_requested = data;
    },
    setpurchaseOrderData_purchase_id(data: number) {
      this.purchaseOrderData_purchase_id = data;
    },
    setupdatePurchaseOrder(data: string) {
      this.updatePurchaseOrder = data;
    }
  }
});

//get role from list staff requsition
export const staffroleid = defineStore({
  id: 'staffroleid',
  state: () => ({
    roleid: 0
  }),
  actions: {
    setRole(data: number) {
      this.roleid = data;
    }
  }
});

//Requisition detail approver control
export const approveOrDisapprove = defineStore({
  id: 'approveOrDisapprove',
  state: () => ({
    statusControl: ''
  }),
  actions: {
    setStatusControl(data: string) {
      this.statusControl = data;
    }
  }
});

//Requisition details id
export const requisitionEdit = defineStore({
  id: 'requisitionEdit',
  state: () => ({
    requisitionEdit: ''
  }),
  actions: {
    setRequestEdit(data: string) {
      this.requisitionEdit = data;
    }
  }
});

export const editRequestedItem = defineStore({
  id: 'editRequestedItem',
  state: () => ({
    editRequestedItem: false
  }),
  actions: {
    setEditRequestedItem(data: boolean) {
      this.editRequestedItem = data;
    }
  }
});

// //pagination page control
export const currentPagePagination = defineStore({
  id: 'currentPagePagination',
  state: () => ({ currentPage: 1 }),
  actions: {
    setCurrentPage(data: number) {
      this.currentPage = data;
    }
  }
});

// //
export const pageSkip = defineStore({
  id: 'pageSkip',
  state: () => ({ skip: 1 }),
  actions: {
    setCurrentSkip(data: number) {
      this.skip = data;
    }
  }
});

// //count handling
export const counter = defineStore({
  id: 'counter',
  state: () => ({ count: 0 }),
  actions: {
    setCounter(data: number) {
      this.count = data;
    }
  }
});

//requisition data storage
export const requisitions = defineStore({
  id: 'fetchallrequisitions',
  state: () => ({
    requisitionData: [] as Requisition[]
  }),
  actions: {
    setRequisitionData(data: Requisition[]) {
      this.requisitionData = data as Requisition[];
    }
  }
});

//capapprover data storage
export const capapprovers = defineStore({
  id: 'requisitions',
  state: () => ({
    capapproversData: [] as CapApproverInt[]
  }),
  actions: {
    setCapapproversData(data: CapApproverInt) {
      this.capapproversData.push(data);
    }
  }
});

//fetching all capapprovers for pagination
export const allCapapprovers = defineStore('allCapapprovers', {
  actions: {
    fetchAllCapApprovers(variable: { input: { skip: number; take: number } }) {
      const data = useQuery(GET_ALL_CAPAPPROVER, variable);
      return data;
    }
  }
});

//fetching all requisition
export const allRequisition = defineStore('allRequisition', {
  actions: {
    fetchllrequisition(variable: {
      input: { user_id: number; skip: number; take: number };
    }) {
      const data = useQuery(GET_ALL_REQUISITION, variable);
      return data;
    }
  }
});

// edit cap approver form
export const editCapApprover = defineStore({
  id: 'editCapApprover',
  state: () => ({
    confirmEdit: false,
    id: 0,
    approverId: 0,
    capApprover: '',
    currency: '',
    lowerLimit: '',
    upperLimit: '',
    description: ''
  }),
  actions: {
    setConfirmEdit(data: boolean) {
      this.confirmEdit = data;
    },
    setId(data: number) {
      this.id = data;
    },
    setApproverId(data: number) {
      this.approverId = data;
    },
    setCapapprovers(data: string) {
      this.capApprover = data;
    },
    setCurrency(data: string) {
      this.currency = data;
    },
    setLowerLimit(data: string) {
      this.lowerLimit = data;
    },
    setUpperLimit(data: string) {
      this.upperLimit = data;
    },
    setDescription(data: string) {
      this.description = data;
    },
    //return state variables
    getCapApproverState(){
      return{
        confirmEdit: this.confirmEdit,
        id: this.id,
        approverId: this.approverId,
        capApprover: this.capApprover,
        currency: this.currency,
        lowerLimit: this.lowerLimit,
        upperLimit: this.upperLimit,
        description: this.description
      };
    }
  }
});

// change capApprover form Title
export const changeCapApproverFormHeader = defineStore({
  id: 'changeCapApproverFormHeader',
  state: () => ({
    title: ''
  }),
  actions: {
    setTitle(data: string) {
      this.title = data;
    }
  }
});

// archive cap approver
export const archiveCapApprover = defineStore({
  id: 'archiveCapApprover',
  state: () => ({
    id: 0,
    archive: false,
    unarchive: false
  }),
  actions: {
    setId(data: number) {
      this.id = data;
    },
    setArchive(data: boolean) {
      this.archive = data;
    },
    setUnarchive(data: boolean) {
      this.unarchive = data;
    }
  }
});

//use to toggle Edit form
export const toggleEditForm = defineStore({
  id: 'toggleEditForm',
  state: () => ({
    toggleEditDialogue: false
  }),
  actions: {
    setToggleEditForm(data: boolean) {
      this.toggleEditDialogue = data;
    }
  }
});

//edit requisition store
export const editRquisition = defineStore({
  id: 'editRquisition',
  state: () => ({
    requestId: 0,
    itemname: '',
    priority: '',
    currency: '',
    category_id: 0,
    unitprice: 0,
    quantity: 0,
    itemspecification: '',
    requesttype: '',
    depOrProjEditManagerId: 0,
    depOrProjEditName: '',
    depOrProjUserEditFirstName: '',
    depOrProjUserLastEditName: '',
    depOrProjUserEditEmail: '',
    justifyrequest: '',
    attachments: [] as { file_name: string; file_url: string }[]
  }),
  actions: {
    setRequestId(data: number) {
      this.requestId = data;
    },
    setItemname(data: string) {
      this.itemname = data;
    },
    setPriority(data: string) {
      this.priority = data;
    },
    setCategory(data: number) {
      this.category_id = data;
    },
    setCurrency(data: string) {
      this.currency = data;
    },
    setUnitprice(data: number) {
      this.unitprice = data;
    },
    setQuantity(data: number) {
      this.quantity = data;
    },
    setItemspecification(data: string) {
      this.itemspecification = data;
    },
    setRequesttype(data: string) {
      this.requesttype = data;
    },
    setRequestTypeMnagerId(data: number) {
      this.depOrProjEditManagerId = data;
    },
    setRequestTypeProDepName(data: string) {
      this.depOrProjEditName = data;
    },
    setRequestTyFirstname(data: string) {
      this.depOrProjUserEditFirstName = data;
    },
    setRequestTypeLastname(data: string) {
      this.depOrProjUserLastEditName = data;
    },
    setRequestTypeEmail(data: string) {
      this.depOrProjUserEditEmail = data;
    },
    setJustifyrequest(data: string) {
      this.justifyrequest = data;
    },
    setAttacments(data: { file_name: string; file_url: string }[]) {
      this.attachments.push(...data);
    }
  }
});

// fetch all cap approvers in the database
// export const listCapApprovers = defineStore('listCapApprovers', {
//   actions: {
//     fetchAllCapApprovers() {
//       const data = useQuery(GET_ALL_CAPAPPROVERS);
//       return data;
//     }
//   }
// });

export const listCapApprovers = defineStore('listCapApprovers', {
  actions: {
    fetchAllCapApprovers() {
      const data = useQuery(FILTER_CAPAPPROVERS, {
          "input": {
            "skip": 0,
            "take": 1000,
            "is_active": null
          }
      });
      return data;
    }
  }
});



//fetch filtered cap approvers
export const filterCapApprovers = defineStore('filterCapApprovers', {
  actions: {
    fetchFilteredCapApprovers(variable: {
      input: { skip: number; take: number; is_active: boolean | null };
    }) {
      const data = useQuery(FILTER_CAPAPPROVERS, variable);
      return data;
    }
  }
});

//handle data to create supplier
export const allSupplier = defineStore('allSupplier', {
  state: () => ({
    allSuppliers: [] as Supplier[],
    editSupplier:
      localStorage.getItem('editSupplier') === 'true' ? true : false,
    supplier: {} as Supplier
  }),
  actions: {
    setallSupplier(data: Supplier[]) {
      this.allSuppliers = data;
    },
    setEditSupplier(data: boolean) {
      this.editSupplier = data;
      localStorage.setItem('editSupplier', data.toString());
    },
    setSupplier(data: Supplier) {
      this.supplier = data;
    }
  }
});

export const fetchAllrequisition = defineStore('fetchAllrequisition', {
  actions: {
    fetchFilteredCapApprovers(variable: {
      input: { skip: number; take: number };
    }) {
      const data = useQuery(GET_ALL_REQUISITION, variable);
      return data;
    }
  }
});

export const supplierDetails = defineStore('supplierDetails', {
  state: () => ({
    businessDetails: {} as BusinessDetails,
    bankDetails: {} as BankDetails,
    uploadedDocuments: {} as Attachments
  }),
  actions: {
    setBusinessDetails(data: BusinessDetails) {
      this.businessDetails = data;
    },
    setBankDetails(data: BankDetails) {
      this.bankDetails = data;
    },
    setUploadedDocuments(data: Attachments) {
      this.uploadedDocuments = data;
    }
  }
});

export const getUploadedDocuments = defineStore('handleUploadedDocuments', {
  state: () => ({
    ssnitRegistrationFiles: [] as File[],
    taxFiles: [] as File[],
    vatFiles: [] as File[],
    ssnitClearanceFiles: [] as File[],
    registrationDocumentFiles: [] as File[],
    incorporationFiles: [] as File[],
    priceFiles: [] as File[],
    uploadedReferenceId: [] as {
      file: File;
      referenceId: string;
    }[],
    editedDocuments: [] as Attachments[]
  }),
  actions: {
    setSsnitRegistrationFiles(data: File) {
      this.ssnitRegistrationFiles.push(data);
    },
    setTaxFiles(data: File) {
      this.taxFiles.push(data);
    },
    setVatFiles(data: File) {
      this.vatFiles.push(data);
    },
    setSsnitClearanceFiles(data: File) {
      this.ssnitClearanceFiles.push(data);
    },
    setRegistrationDocumentFiles(data: File) {
      this.registrationDocumentFiles.push(data);
    },
    setIncorporationFiles(data: File) {
      this.incorporationFiles.push(data);
    },
    setPriceFiles(data: File) {
      this.priceFiles.push(data);
    }
  }
});

//edit supplier
export const editSupplier = defineStore('editSupplier', {
  state: () => ({
    isTaxExempt: false,
    supplierName: '',
    contactPerson: '',
    supplierEmail: '',
    phoneNumber: '',
    streetAddress: '',
    tinNumber: '',
    numberOfBranches: '1',
    productDescription: '',
    accountName: '',
    accountNumber: '',
    bankBranch: '',
    swiftCodeNumber: '',
    registrationNumber: '',
    documents: [] as Attachments[],
    categoryId: '',
    userId: 0,
    id: 0,
    documentsArr: [] as File[]
  }),
  actions: {
    setSupplierResponse(supplier: Supplier) {
      this.isTaxExempt = supplier?.is_tax_exempt;
      this.supplierName = supplier?.supplier_name;
      this.categoryId = supplier?.category_id;
      this.userId = supplier?.id;
      this.contactPerson = supplier?.contact_person;
      this.supplierEmail = supplier?.supplier_email;
      this.streetAddress = supplier?.street_address;
      this.tinNumber = supplier?.business_tin_no;
      this.numberOfBranches = supplier?.no_of_branches;
      this.productDescription = supplier?.business_description;
      this.accountName = supplier?.business_acc_name;
      this.accountNumber = supplier?.bank_acct_no;
      this.bankBranch = supplier?.bank_branch;
      this.swiftCodeNumber = supplier?.swift_code;
      this.registrationNumber = supplier?.business_reg_no;
      this.documents = supplier?.documents;
      this.phoneNumber = supplier?.phone_number;
      this.id = supplier?.id;
    }
  }
});

export const fetchAllSuppliers = defineStore('fetchAllSuppliers', {
  actions: {
    fetchSuppliers(variable: {
      input: { keyword: string; skip: number; take: number };
    }) {
      const data = useQuery(SEARCH_SUPPLIER, variable);
      return data;
    }
  }
});

export const checkRequisitionRoute = defineStore('checkRequisitionRoute', {
  state: () => ({
    requisitionRoute: localStorage.getItem('requisitionRoute') || ''
  }),
  actions: {
    setRequisitionRoute(data: string) {
      this.requisitionRoute = data;
      localStorage.setItem('requisitionRoute', data);
    }
  }
});

export const handlesettingsnavigations = defineStore('handlesettingsnavigations', {
  state: ()=>({
   selectedcomp:""
  }),
  actions:{
   setSelectedcomp(data:string){
    this.selectedcomp = data;
   }
  }
});

export const settingsnavigation = defineStore("settingsnavigation", {
  state: ()=>({
    settingNav:""
  }),
  actions:{
    setSettingsNav(data:string){
      this.settingNav = data;
    }
  },
  persist:true
});