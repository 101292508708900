import { userType } from '@/apps/authentication/types/AuthTypes';
import { defineStore } from 'pinia';

export const useStore = defineStore({
  id: 'login_data',
  state: () => ({
    email: '',
    id: 0,
    manager: 0,
    adviser: [] as Array<number>,
    url: '',
    userName: '',
    allUsers: [] as Array<object>,
    user: {} as userType,
    profilePicture: '',
    empInfoUsers: [] as Array<object>,
    permissions: {},
    currentStep: null,
    totalStep: null,
    onboardingPending: false as boolean,
    userInfo: {} as userType,
    cdcAdvisor: '' as string | number,
    loanuser: 0,
    showAll: true,
  }),
  getters: {},
  actions: {
    setLoanUser(data: number) {
      this.loanuser = data;
    },
    setEmail(email: string) {
      this.email = email;
    },
    setId(id: number) {
      this.id = id;
    },
    setManager(id: number) {
      this.manager = id;
    },
    setAdviser(ids: Array<number>) {
      this.adviser = ids;
    },
    setURL(url: string) {
      this.url = url;
    },
    setName(name: string) {
      this.userName = name;
    },
    setAllUsers(data: Array<object>) {
      this.allUsers = data;
    },
    setUser(data: userType) {
      this.user = data;
    },
    setProfilePicture(url: string) {
      this.profilePicture = url;
    },
    setEmpInfoUsers(data: Array<object>) {
      this.empInfoUsers = data;
    },
    setPermission(data: object) {
      this.permissions = data;
    },
    setOnboardingStatus(data: boolean) {
      this.onboardingPending = data;
    },
    setUserInfo(data: userType) {
      this.userInfo = data;
    },
    setCdcAdvisor(data: string | number) {
      this.cdcAdvisor = data;
    }
  },
  persist: true
});

export const useConfig = defineStore({
  id: 'config',
  state: () => ({
    departments: [] as Array<object>,
    positions: [] as Array<object>
  }),
  getters: {},
  actions: {
    setDepartments(data: Array<object>) {
      this.departments = data;
    },
    setPositions(data: Array<object>) {
      this.positions = data;
    }
  },
  persist: true
});

export const userInfo = defineStore({
  id: 'user_info',
  state: () => ({
    userInfo: {} as object,
    empInfo: [] as Array<object>,
    empStatus: [] as Array<object>
  }),
  getters: {},
  actions: {
    setUser(data: object) {
      this.userInfo = data;
    },

    setEmpInfo(data: Array<object>) {
      this.empInfo = data;
    },

    setEmpStatus(data: Array<object>) {
      this.empStatus = data;
    }
  },
  persist: false
});
